
    $color-black: #000000;
    $color-default: #071420;
    $color-white: #ffffff;
    $color-green: #16b408;
    $color-footergreen: #02B65F;
    $color-darkgreen: #069852;
    $color-inputborder: #bbb;
    $color-greenlight: #e8f8e6;

    $color-mobfooter-bg: #01b961;

    $color-error-border: #d60000;
    $color-error-bg: #f3e4e0;

    $color-inputbg-ok:  #e8f8e6;
    $color-green-mid:   #8ad983;