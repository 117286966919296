@use "../helpers/_colors";

html,
body {
    height: 100%;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: colors.$color-default;
    font-size: 16px;
    font-family: 'Inter', arial, sans-serif;
    padding: 0;
    margin: 0;
}
