@use "../helpers/_colors";
@use '../helpers/_functions';

html.error {
    min-width: auto;
}

.clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


body.error {
    border-top: functions.rem-calc(57) solid colors.$color-default;
    background-color: #e9e9e6;
    background-image: none;
    min-width: auto;
    padding-left: functions.rem-calc(30);
    padding-right: functions.rem-calc(30);

    * {
        box-sizing: border-box;
    }

    img {
        max-width: 100%;
    }

    i, em {
        font-style: normal;
    }

    ol {
        list-style: none;
        padding-left: 0;
    }

    h1, h2, h3 {
        margin: 0;
        padding: 0;
    }

    h1 {
        margin-bottom: functions.rem-calc(12);
    }

    h3 {
        margin-top: functions.rem-calc(40);
    }

    a {
        color: colors.$color-green;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .errorpage {
        width: 100%;
        max-width: functions.rem-calc(1100);
        min-width: auto;
        margin: 0 auto;
        position: relative;
        top: functions.rem-calc(-43);

        .logo img {
            position: relative;
            top: functions.rem-calc(3);
        }
        .logo-text, .logo-text strong {
            color: #fff;
            font-size: functions.rem-calc(21);
        }

        .logo-text mark {
            background: transparent;
            color: colors.$color-green;
            font-weight: bold;
        }
    }

    .errorpage-content {
        margin-top: functions.rem-calc(100);
        font-size: functions.rem-calc(20);
        line-height: functions.rem-calc(24);

        h1 {
            font-size: functions.rem-calc(45);
            line-height: functions.rem-calc(48);
            font-weight: 800;
            font-style: normal;
        }

        h1.code {
            margin-top: functions.rem-calc(80);
            line-height: functions.rem-calc(70);

            em {
                font-size: functions.rem-calc(200);
                font-weight: 600;
                color: colors.$color-inputbg-ok;
                -webkit-text-fill-color: colors.$color-inputbg-ok;
                -webkit-text-stroke: functions.rem-calc(7) colors.$color-green;
                position: relative;

                i {
                    position: absolute;
                    left: 0;
                    -webkit-text-fill-color: colors.$color-inputbg-ok;
                    -webkit-text-stroke: functions.rem-calc(1) transparent;
                }
            }

            span {
                text-transform: uppercase;
                font-size: functions.rem-calc(42);
                font-weight: 500;
                color: colors.$color-green-mid;
                text-shadow: none;
                position: relative;
                top: functions.rem-calc(-23);
                left: functions.rem-calc(10);
            }

            &:after {
                display: none;
                font-size: functions.rem-calc(30);
            }
        }

        h2 {
            color: colors.$color-green;
            font-size: functions.rem-calc(28);
            line-height: functions.rem-calc(30);
            margin-top: functions.rem-calc(30);
            font-weight: 600;
        }

        h1.code + h1 {
            margin-top: 0;
        }

        h3 {
            font-size: functions.rem-calc(21);
            font-weight: 600;
        }

        h1 + h3 {
            font-size: functions.rem-calc(24);
        }

        ul {
            list-style: disc;
            margin-left: functions.rem-calc(20);
            li {
                margin-top: functions.rem-calc(8);
            }
        }

        .error-icon {
            width: 30%;
            float: left;
            box-sizing: border-box;
        }

        .error-text {
            width: 70%;
            float: left;
            box-sizing: border-box;

            padding-left: functions.rem-calc(50);
        }

        ol {
            margin-top: functions.rem-calc(10);
            display: inline-block;

            li {
                margin-top: functions.rem-calc(20);

                a {
                    display: block;
                    svg {
                        float: right;
                        width: functions.rem-calc(16);
                        margin-left: functions.rem-calc(15);
                        position: relative;
                        top: functions.rem-calc(-2);
                    }
                }
            }
        }

        .eyes {
            position: absolute;
            margin-left: functions.rem-calc(10);
            margin-top: functions.rem-calc(-6);

            i {
                width: functions.rem-calc(22);
                height: functions.rem-calc(36);
                border-radius: 50%;
                border: 1.5px solid colors.$color-black;
                display: inline-block;
                background-color: colors.$color-white;
                position: relative;

                &:before {
                    content: '';
                    display: inline-block;
                    border: 1.5px solid colors.$color-black;
                    width: functions.rem-calc(14);
                    height: functions.rem-calc(14);
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-42%);
                    background-color: colors.$color-inputbg-ok;
                }

                &:after {
                    content: '';
                    display: inline-block;
                    width: functions.rem-calc(9);
                    height: functions.rem-calc(9);
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: functions.rem-calc(1);
                    transform: translateY(-45%);
                    background-color: colors.$color-black;
                }
            }

            i + i {
                margin-left: functions.rem-calc(2);
            }
        }

        @extend .clearfix;
    }

    pre {
        display: block;
        padding: functions.rem-calc(20);
        background-color: colors.$color-white;
        overflow-x: scroll;
        border: functions.rem-calc(1) solid colors.$color-default;
        margin-top: functions.rem-calc(50);
        font-size: functions.rem-calc(15);
    }

    @media screen and (max-width: 1100px) {
        .errorpage-content {
            margin-top: functions.rem-calc(30);
        }

        .errorpage-content .error-icon {
            text-align: center;
            margin-bottom: functions.rem-calc(30);
            img {
                max-width: functions.rem-calc(200);
            }
        }
        .errorpage-content .error-icon,
        .errorpage-content .error-text {
            width: 100%;
            padding-left: 0;
        }
    }
}