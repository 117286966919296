@use "../helpers/_colors";
@use "../helpers/_mixins";
@use '../helpers/_functions';

body {
    overflow-x: hidden;
    background-image: url(/img/bg-pattern.png);
    background-size: 230px 230px;
    background-repeat: repeat;
    background-position: top left;
}

@media (min-width: 890px) {
    body#pagebody:before, body#pagebody:after {
        content: '';
        display: block;
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        width: 50%;
    }
    body#pagebody:after {
        background-color: colors.$color-white;
        width: 50%;
        left: 50%;
    }
}


body#pagebody.error:before, body#pagebody.error:after {
    display: none;
}

body#pagebody.error {
    background: none;
    background-color: #f5f5f5;
}

main.main {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &.aside-open:after {
        content: '';
        z-index: 10;
        width: 100%;
        height: functions.rem-calc(3000);
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    h1, h2 {
        color: colors.$color-default;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: functions.rem-calc(18);
        margin-top: functions.rem-calc(23);
        font-weight: normal;
    }

    h1 {
        font-weight: 800;
        font-size: functions.rem-calc(55);
        line-height: functions.rem-calc(56);
        margin-top: functions.rem-calc(25);
        margin-bottom: functions.rem-calc(36);

        span {
            color: colors.$color-green;
        }
    }

}

.guide {
    width: functions.rem-calc(90);
    position: absolute;
    right: functions.rem-calc(30);
    margin-top: functions.rem-calc(15);
}

section {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

article {
    float: left;
    width: 50%;
    min-height: 100%;
    background-color: colors.$color-white;
    position: relative;
    box-sizing: border-box;
}

article .inner {
    max-width: functions.rem-calc(800);
    padding: functions.rem-calc(30);
    box-sizing: border-box;
    position: relative;
}

article > .inner {
    min-height: calc(100vh - 245px);
    padding-top: functions.rem-calc(15);
}

.launch {
    position: absolute;
    top: 0;
    left: functions.rem-calc(30);
    width: auto;
    padding: 0 functions.rem-calc(20);
    height: functions.rem-calc(26);
    font-size: functions.rem-calc(11);
    background-color: colors.$color-default;
    color: colors.$color-white;
    text-align: center;
    line-height: functions.rem-calc(26);
    display: none;
}

ul {
    list-style: none;
    padding: 0;
    @include mixins.clearfix();
}

ul li {
    width: 29%;
    float: left;
    padding: 0;
    font-size: functions.rem-calc(16);
    line-height: functions.rem-calc(20);
    padding-left: functions.rem-calc(18);
    box-sizing: border-box;
    position: relative;

    svg {
        position: absolute;
        top: functions.rem-calc(4);
        left: 0;
    }
}

ul li:nth-child(n+2) {
    margin-left: 6.5%;
}

.cta-block {
    border: 1px solid colors.$color-green;
    background-color: colors.$color-greenlight;
    color: colors.$color-default;
    padding: functions.rem-calc(25) functions.rem-calc(25);
    margin: functions.rem-calc(50) 0;

    h3 {
        font-size: functions.rem-calc(18);
        margin: functions.rem-calc(3) 0 functions.rem-calc(10);
        padding: 0;
    }

    p {
        font-size: functions.rem-calc(16);
        line-height: functions.rem-calc(20);
        margin-bottom: functions.rem-calc(20);
    }

    a {
        background-color: colors.$color-green;
        color: colors.$color-white;
        font-size: functions.rem-calc(14);
        font-weight: bold;
        display: inline-block;
        height: functions.rem-calc(34);
        width: functions.rem-calc(184);
        text-align: center;
        text-decoration: none;
        line-height: functions.rem-calc(34);
        border-radius: functions.rem-calc(15);
        margin: functions.rem-calc(5) 0;
        //border-bottom: functions.rem-calc(2) solid colors.$color-default;

        svg {
            display: none;
            margin-right: functions.rem-calc(5);
            position: relative;
            top: functions.rem-calc(2);
        }

        //&:active {
        //    border-top: functions.rem-calc(1) solid colors.$color-default;
        //    border-bottom: functions.rem-calc(1) solid colors.$color-default;
        //}
    }
}

article footer {
    width: 100%;
    background-color: colors.$color-white;
    height: functions.rem-calc(245);
    position: relative;

    .inner {
        padding: functions.rem-calc(10) functions.rem-calc(30);
        float: none;
    }

    .last {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 775px;
        overflow: hidden;
        height: functions.rem-calc(80);

        svg {
            transform: scale(0.3);
            transform-origin: right top;
            position: absolute;
            right: 0.625rem;
            top: 0px;
        }

        &:before {
            content: '';
            border-top: 1px solid colors.$color-green;
            width: 100%;
            height: 0;
            position: absolute;
            top: 39px;
            right: 42px;
        }

        div {
            display: inline-block;
            color: colors.$color-green;
            font-size: 0.6875rem;
            position: absolute;
            right: 1.5rem;
            top: 45px;
            font-weight: 500;
        }
    }

    h4 {
        font-size: functions.rem-calc(13);

        a {
            color: colors.$color-default;
        }

        svg {
            float: left;
            margin-right: functions.rem-calc(10);
            position: relative;
            top: functions.rem-calc(-5);
        }
    }

    p {
        font-size: functions.rem-calc(13);
        line-height: functions.rem-calc(16);

    }

    p.sub {
        margin-top: functions.rem-calc(20);
        font-size: functions.rem-calc(10);

        a {
            color: colors.$color-default;
        }
    }
}

aside.sidebar {
    float: left;
    width: 50%;
    height: 100%;
    position: relative;
    //border-right: 1px solid #ababab;
    box-sizing: border-box;

    //div {
    //    width: 100%;
    //    height: 60%;
    //    display: block;
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    background-image: url(/img/background.jpg);
    //    background-repeat: no-repeat;
    //    background-position: top left;
    //    background-size: cover;
    //    z-index: 2;
    //}

    img {
        position: absolute;
        z-index: 4;
        width: auto;
        height: auto;
        width: 100%;
        max-width: functions.rem-calc(700);
        top: 40px;
        right: 10px;
    }

    h3 {
        font-weight: normal;
        font-size: functions.rem-calc(28);
        position: absolute;
        z-index: 5;
        width: 95%;
        max-width: functions.rem-calc(660);
        top: 00px;
        right: 10px;
        color: colors.$color-green;
        letter-spacing: 0.5px;

        strong {
            font-weight: bold;
        }
    }
}
//
//aside.sidebar:after {
//    content: '';
//    display: block;
//    height: functions.rem-calc(320);
//    margin-top: functions.rem-calc(-318);
//    width: 100%;
//    background: linear-gradient(0deg, rgba(7,20,32,1) 0%, rgba(0,0,0,0) 100%);
//    position: absolute;
//    top: 60%;
//    left: 0;
//    z-index: 3;
//
//}
//
//aside.sidebar:before {
//    content: '';
//    display: block;
//    height: functions.rem-calc(140);
//    width: 100%;
//    background: linear-gradient(0deg, rgba(7,20,32,1) 0%, rgba(0,0,0,0) 100%);
//    position: absolute;
//    bottom: 4.5%;
//    left: 0;
//    z-index: 5;
//
//}

article aside.sidebar {
    display: none;
}

aside.form {
    z-index: 20;
    background-color: colors.$color-white;
    width: 100%;
    height: 100%;
    width: functions.rem-calc(480);
    max-width: 85%;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    padding: functions.rem-calc(40) functions.rem-calc(90) functions.rem-calc(40) functions.rem-calc(35);
    box-sizing: border-box;
    transition: transform .3s ease-out;
    display: none;
    overflow: auto;

    p {
        font-size: functions.rem-calc(13);
        line-height: functions.rem-calc(16);
    }

    form {
        margin-top: functions.rem-calc(30);
        width: 100%;
        max-width: 90%;
    }

    input[type=text], input[type=email], textarea {
        font-size: functions.rem-calc(14);
        line-height: functions.rem-calc(18);
        padding: functions.rem-calc(6) functions.rem-calc(10) functions.rem-calc(5);
        width: 100%;
        display: block;
        margin-bottom: functions.rem-calc(12);
        border: functions.rem-calc(1) solid colors.$color-inputborder;
        color: colors.$color-default;
        font-family: 'Inter', sans-serif;
        box-sizing: border-box;
    }

    textarea {
        height: functions.rem-calc(100);
    }

    input[type=submit] {
        display: inline-block;
        font-size: functions.rem-calc(15);
        font-weight: bold;
        line-height: functions.rem-calc(30);
        width: functions.rem-calc(148);
        height: functions.rem-calc(32);
        margin-top: functions.rem-calc(15);
        border: 0;
        background-color: colors.$color-green;
        color: colors.$color-white;
        border-radius: functions.rem-calc(15);
        cursor: pointer;
        font-family: 'Inter', sans-serif;
    }

    input[type=submit]:active {
        position: relative;
        top: functions.rem-calc(1);
    }
}

aside #mc_embed_signup input.mce_inline_error {
    border-color: colors.$color-error-border;
}
aside #mc_embed_signup div.mce_inline_error {
    border: 1px solid colors.$color-error-border;
    color: colors.$color-default;
    background-color: colors.$color-error-bg;
    position: relative;
    top: functions.rem-calc(-5);
    font-weight: normal;
    font-size: functions.rem-calc(13);
}
aside #mce-success-response {
    background-color: colors.$color-darkgreen;
    color: colors.$color-white;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

#close-aside {
    position: absolute;
    top: functions.rem-calc(25);
    right: functions.rem-calc(50);
}

aside.form.show {
    display: block;
}

main.aside-open + aside.form {
    transform: translateX(0);
}

@media (min-width: 890px) {
    .m {
        display: none;

    }
    .d {
        display: block;
    }
}

@media (max-width: 889px) {

    .m {
        display: block;

        h3 {
            font-weight: normal;
            font-size: functions.rem-calc(28);
            z-index: 5;
            color: colors.$color-green;
            letter-spacing: 0.5px;

            strong {
                font-weight: bold;
            }
        }
    }
    .d {
        display: none;
    }

    body#pagebody {

        background-color: colors.$color-white;

        main {
            height: auto;
        }

        h1 {
            font-size: functions.rem-calc(32);
            line-height: functions.rem-calc(32);
            margin-bottom: functions.rem-calc(10);
            padding-right: 0;
        }

        h2 {
            margin-top: functions.rem-calc(60);
            padding-right: functions.rem-calc(100);
        }

        .guide {
            width: functions.rem-calc(70);
            margin-top: functions.rem-calc(-70);
        }

        article {
            width: 100%;

            .inner {
                max-width: none;
            }
        }

        article .inner {
            min-height: auto;
            padding: functions.rem-calc(20) functions.rem-calc(20) 0;
        }

        ul li {
            width: 100%;
            margin-bottom: functions.rem-calc(20);
            font-size: functions.rem-calc(14);
        }

        ul li:nth-child(n+2) {
            margin-left: 0;
        }

        aside.sidebar {
            display: none;
        }

        article aside.sidebar {
            width: 100%;
            float: none;
            max-height: functions.rem-calc(320px);
            height: functions.rem-calc(320px);
            display: block;
            overflow: hidden;
            background-image: url(/img/bg-pattern.png);
            background-size: 110px 110px;
            background-repeat: repeat;
            background-position: top left;

            img {
                max-width: none;
                height: 90%;
                width: auto;
                position: relative;
                top: 5%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .cta-block {
            margin: 0 functions.rem-calc(-20);
            border-left: 0;
            border-right: 0;
            padding: functions.rem-calc(35) functions.rem-calc(20);
        }

        article footer {
            background-color: colors.$color-white;
            height: auto;

            .inner {
                padding-left: functions.rem-calc(20);
                padding-right: functions.rem-calc(20);
                padding-bottom: 0;
            }

            .last {
                position: relative;
            }

            p.sub {
                background-color: colors.$color-white;
                margin: functions.rem-calc(20) functions.rem-calc(-20) 0;
                display: block;
                padding: functions.rem-calc(20);
                font-size: functions.rem-calc(10);

                a {
                    white-space: nowrap;
                }
            }
        }

        aside.form {
            padding: functions.rem-calc(30) functions.rem-calc(20) functions.rem-calc(40) functions.rem-calc(20);

            form {
                max-width: 100%;
            }
        }

        #close-aside {
            top: functions.rem-calc(20);
            right: functions.rem-calc(20);
        }

    }

}


.home {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;

    .logo-icon {
        margin: 0 auto 30px;
    }

    h1 {
        font-size: 70px;
        letter-spacing: 2px;
        font-weight: 400;
    }

    h1, h2 {
        margin: 3px 0;
    }

    h2 {
        font-size: 40px;
        font-weight: 400;
    }

}

@media (max-width: 600px) {

    .home {
        .logo-icon {
            width: 150px;
            height: 150px;
        }

        h1 {
            font-size: 35px;
        }
        h2 {
            font-size: 20px;
        }
    }

}
