@use "../helpers/_colors";

    .logo-icon {
        background-color: colors.$color-green;
        width: 300px;
        height: 300px;
        border-radius: 15%;
        position: relative;
        transition: all .2s ease-in-out;

        img {
            position: absolute;
            height: 60%;
            bottom: 15%;
            right: 18%;
        }

        &:before {
            content: '';
            width: 42%;
            height: 0.4%;
            min-height: 1px;
            background-color: colors.$color-white;
            position: absolute;
            left: 0;
            bottom: 15%;
        }
    }